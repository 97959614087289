@import "~antd/dist/antd.css";

@font-face {
  font-family: Sommet;
  src: url("./fonts/Sommet.otf") format("opentype");
}

.landing-page {
  color: floralwhite;
  font-family: "Sommet";
}

/* Remove indent from tree data in tables */
.ant-table-row-level-1 .ant-table-row-expand-icon {
  display: none;
}

.quick-access-card {
  box-shadow: 0 0 10px gray;
}

.quick-access-card:hover {
  box-shadow: 0 0 10px black;
  cursor: pointer;
}
